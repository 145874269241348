import React from "react"

import Layout from "../components/layout"
import SEO from "../components/seo"
import Home from "../components/Home"

const VoronezhPage = props => {
  const { location } = props
  return (
    <Layout path={location.pathname}>
      {
        // eslint-disable-next-line
        <SEO
          title="Прием катализаторов и сажевых фильтров в Нижнем Новгороде и области"
          description="Бесплатный выезд специалиста по Нижнему Новгороду и Нижегородской области. 
        Мы гарантируем проведение анализа и выплату в день получения отработанного материала. 
        Наша компания скупает катализаторы и сажевые фильтры по высоким ценам до 75% LME."
        />
      }
      <Home city="Нижний Новгород" />
    </Layout>
  )
}

export default VoronezhPage
